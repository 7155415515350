/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Spinner from "./components/Spinner/Spinner";
import * as auth from "./auth-provider";
import { useAsync } from "./utils/hooks";

const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./authenticated-app")
);
const UnauthenticatedApp = React.lazy(() => import("./unauthenticated-app"));

async function getUser() {
  let user = await auth.getUser();
  return user;
}

function App() {
  const {
    data: user,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync();

  React.useEffect(() => {
    run(getUser());
  }, [run]);

  const login = (userData) => {
    setData(userData);
  };
  const logout = (form) => {
    auth.logout();
    setData(null);
  };
  if (isLoading || isIdle) {
    return <h1>Loading user information</h1>;
  }

  if (isError) {
    return (
      <div
        css={{
          color: "red",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>Uh oh... There's a problem. Try refreshing the app.</p>
        <pre>{error.message}</pre>
      </div>
    );
  }
  if (isSuccess) {
    return (
      <React.Suspense fallback={<Spinner />}>
        {
          user ? <AuthenticatedApp logout={logout} user={user} /> : <UnauthenticatedApp login={login} />
        }
      </React.Suspense>
    );
  }
  return null;
}

export { App };
