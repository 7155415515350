import { createSlice } from "@reduxjs/toolkit";

export const changeMapView = createSlice({
  name: "changeMapView",
  initialState: {
    viewport: {
      latitude: 37.830348,
      longitude: -100.486052,
      zoom: 3.5,
      pitch: 40,
    },
    bounds: null,
    isSatellite: false,
  },
  reducers: {
    changeViewport: (state, action) => {
      if (action.payload) {
        if (action.payload.bounds) {
          state.bounds = action.payload.bounds;
        } else {
          let viewport = action.payload;
          state.viewport = {
            ...state.viewport,
            ...viewport,
          };
        }
        return;
      }
    },
    changeTheme: (state, action) => {
      state.isSatellite = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeViewport, getStatesGeo, changeTheme } =
  changeMapView.actions;

export default changeMapView.reducer;
