import { createSlice } from "@reduxjs/toolkit";

export const changeAppState = createSlice({
  name: "changeAppState",
  initialState: {
    isLoading: false,
    selectedShipment: null,
    selectedLocation: null,
    selectedFacility: null,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedShipment: (state, action) => {
      state.selectedShipment = action.payload;
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setSelectedFacility: (state, action) => {
      state.selectedFacility = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsLoading,
  setSelectedShipment,
  setSelectedLocation,
  setSelectedFacility,
} = changeAppState.actions;

export default changeAppState.reducer;
