import { configureStore } from "@reduxjs/toolkit";
import changeMapViewReducer from "./feautures/map/changeMapView.js";
import appThemeReducer from "./feautures/app/changeAppState.js";
import getShipmentsReducer from "./feautures/app/getShipments.js";

export default configureStore({
  reducer: {
    mapViewPort: changeMapViewReducer,
    appState: appThemeReducer,
    contents: getShipmentsReducer,
  },
});
