import mockMeAPI from "./utils/mockMeAPI.json";
const localStorageKey = "__user__";

async function getUser() {
  // return client("user/me", {}).then(handleUserResponse);
  try {
    const config = {
      method: "POST",
      body: JSON.stringify({}),
      headers: { "Content-Type": "application/json" },
      credentials: "include"
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "/services/user/me", config);
    const user = await response.json();
    return user;
  } catch (error) {
    console.error(error);
    return null;
  }
}

function handleUserResponse(user) {
  return user;
}

function login({ username, password }) {
  return client("login", { username, password }).then(handleUserResponse);
}

function register({ username, password }) {
  return client("register", { username, password }).then(handleUserResponse);
}

async function logout() {
  window.localStorage.removeItem(localStorageKey);
  if (process.env.NODE_ENV !== "development") {
    client("services/auth/logout", {});
  }
}

async function client(endpoint, data) {
  const config = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    credentials: "include"
  };

  return window.fetch(`/${endpoint}`, config).then(async (response) => {
    let data;
    // some API doesn't return json formate
    if (typeof response === 'object') {
      data = await response;
    } else {
      data = await response.json();
    }
    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
}

export { getUser, login, register, logout, localStorageKey };
