import { createSlice } from "@reduxjs/toolkit";

export const getShipments = createSlice({
  name: "getShipments",
  initialState: {
    cities: [],
    alerts: [],
    deviceHistory: [],
  },
  reducers: {
    getLocations: (state, action) => {
      action.payload.sort((city1, city2) => city1.city_name?.toLowerCase() > city2.city_name?.toLowerCase() ? 1 : -1); //Sorting City by name after fetch, fix for H20-400
      state.cities = action.payload;
    },
    getAlerts: (state, action) => {
      state.alerts = action.payload;
    },
    getDeviceHistory: (state, action) => {
      state.deviceHistory = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { getLocations, getAlerts, getDeviceHistory } =
  getShipments.actions;

export default getShipments.reducer;
