import { Provider } from "react-redux";
import store from "./store.js";
import { App } from "./App.jsx";

import "./index.css";

export default function Root(props) {
  // return <section>{props.name} is mounted!</section>;
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}
